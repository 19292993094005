import request from '@/utils/request'

export function getVipCodeList(keyword, skip, take) {
  return request({
    url: '/VipCode/GetVipCodeList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}

export function getVipCodeListManual(keyword, skip, take) {
  return request({
    url: '/VipCode/GetVipCodeListManual',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}

export function getBridgeVipCodeList(distributorId, keyword, skip, take) {
  return request({
    url: '/VipCode/GetBridgeVipCodeList',
    method: 'post',
    params: {
      distributorId,
      keyword,
      skip,
      take
    }
  })
}

export function getRefreshList(source) {
  return request({
    url: '/VipCode/GetRefreshList',
    method: 'post',
    data: {
      source
    }
  })
}

export function refreshVipCode(code) {
  return request({
    url: '/VipCode/RefreshVipCode',
    method: 'post',
    params: {
      code
    }
  })
}

export function getVipCodeManual(data) {
  return request({
    url: '/VipCode/GetVipCodeManual',
    method: 'post',
    params: {
      mobile: data.mobile,
      count18: data.count18,
      count28: data.count28,
      count38: data.count38,
      count58: data.count58,
      count88: data.count88,
      count188: data.count188
    }
  })
}
