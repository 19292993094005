<template>
  <div class="container">
    <h1>日上券 - 后台领券</h1>
    <div class="filter-box">
      <div class="left-box">
        <div class="item">
          <el-input v-model="params.KeyWords" size="small" placeholder="关键字" clearable />
        </div>
        <div class="item">
          <el-button type="primary" size="small" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          <el-button type="default" size="small" icon="el-icon-close" @click="handleCleanFilter">清除筛选</el-button>
        </div>
      </div>
      <div class="right-box">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="handleShowGetVipCode">领券</el-button>
        <el-button type="primary" size="small" icon="el-icon-refresh" @click="handleRefresh">刷新券状态</el-button>
        <el-button type="primary" size="small" icon="el-icon-download" @click="handleExport">导出 Excel</el-button>
      </div>
    </div>
    <div class="table-box">
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="code" label="券码" min-width="240" />
        <el-table-column prop="kind" label="券类型" min-width="120" />
        <el-table-column prop="startTime" label="有效期开始时间" min-width="140">
          <template #default="scope">
            {{ dateFormat(scope.row.startTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="有效期结束时间" min-width="140">
          <template #default="scope">
            {{ dateFormat(scope.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" min-width="120" />
        <el-table-column prop="membercode" label="会员码" min-width="240" />
        <el-table-column prop="status" label="状态" min-width="100">
          <template #default="scope">
            {{ statusFormat(scope.row.status) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin:20px auto; text-align:center">
      <el-pagination :page-size="params.Take" :total="totalCount" background layout="total, prev, pager, next"
        @current-change="handlePageChange" />
    </div>
    <el-dialog title="正在刷新券状态" v-model="dialogVisible" :show-close="false" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" center width="600px">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="refreshPercent"><span>{{ progressText }}</span>
      </el-progress>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="领券" v-model="getCodeDialogVisible" :show-close="false" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" center width="400px">
      <div class="form-line">
        <div class="label">手机号:</div>
        <div>
          <el-input v-model="getCodeData.mobile"></el-input>
        </div>
      </div>
      <div class="form-line">
        <div class="label">18元券(张):</div>
        <div>
          <el-input-number v-model="getCodeData.count18" :min="0" :max="100"></el-input-number>
        </div>
      </div>
      <div class="form-line">
        <div class="label">28元券(张):</div>
        <div>
          <el-input-number v-model="getCodeData.count28" :min="0" :max="100"></el-input-number>
        </div>
      </div>
      <div class="form-line">
        <div class="label">38元券(张):</div>
        <div>
          <el-input-number v-model="getCodeData.count38" :min="0" :max="100"></el-input-number>
        </div>
      </div>
      <div class="form-line">
        <div class="label">58元券(张):</div>
        <div>
          <el-input-number v-model="getCodeData.count58" :min="0" :max="100"></el-input-number>
        </div>
      </div>
      <div class="form-line">
        <div class="label">88元券(张):</div>
        <div>
          <el-input-number v-model="getCodeData.count88" :min="0" :max="100"></el-input-number>
        </div>
      </div>
      <div class="form-line">
        <div class="label">188元93折券(张):</div>
        <div>
          <el-input-number v-model="getCodeData.count188" :min="0" :max="100"></el-input-number>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" :loading="loadGetVipCodeing" @click="handleGetVipCode">领取</el-button>
          <el-button size="small" @click="getCodeDialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getVipCodeListManual,
    getVipCodeManual,
    getRefreshList,
    refreshVipCode
  } from "@/api/vipCode"
  import {
    formatJsonDate
  } from "../../utils/dateFormat"
  import {
    createFile,
    download
  } from '../../utils/excel'

  export default {
    data() {
      return {
        loading: false,
        params: {
          Skip: 0,
          Take: 20,
          KeyWords: null
        },
        items: [],
        totalCount: 0,
        dialogVisible: false,
        refreshItems: [],
        refreshIndex: 0,
        refreshPercent: 0,
        progressText: '',
        getCodeDialogVisible: false,
        loadGetVipCodeing: false,
        getCodeData: {
          mobile: '',
          count18: 0,
          count28: 0,
          count38: 0,
          count58: 0,
          count88: 0,
          count188: 0
        }
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        getVipCodeListManual(this.params.KeyWords, this.params.Skip, this.params.Take)
          .then(res => {
            if (res.success) {
              this.items = res.items
              this.totalCount = res.totalCount
            }
          })
      },
      dateFormat(time) {
        return formatJsonDate(time)
      },
      statusFormat(status) {
        switch (status) {
          case 0:
            return '未使用'
          case 1:
            return '已使用'
          case 2:
            return '已过期'
          default:
            return status
        }
      },
      handleFilter() {
        this.params.Skip = 0
        this.init()
      },
      handleCleanFilter() {
        this.params.Skip = 0
        this.params.KeyWords = null
        this.init()
      },
      handleShowGetVipCode() {
        this.getCodeData = {
          mobile: '',
          count18: 0,
          count28: 0,
          count38: 0,
          count58: 0,
          count88: 0,
          count188: 0
        }
        this.loadGetVipCodeing = false
        this.getCodeDialogVisible = true
      },
      handleGetVipCode() {
        if (this.getCodeData.mobile.length != 11 ||
          !this.getCodeData.mobile.startsWith('1')) {
          this.$message({
            message: '请输入正确的手机号',
            type: 'error'
          })
          return
        }

        let totalCount =
          this.getCodeData.count18 +
          this.getCodeData.count28 +
          this.getCodeData.count38 +
          this.getCodeData.count58 +
          this.getCodeData.count88 + 
          this.getCodeData.count188

        if (totalCount <= 0 ||
          this.getCodeData.count18 < 0 ||
          this.getCodeData.count28 < 0 ||
          this.getCodeData.count38 < 0 ||
          this.getCodeData.count58 < 0 ||
          this.getCodeData.count88 < 0 ||
          this.getCodeData.count188 < 0) {
          this.$message({
            message: '请输入正确数量',
            type: 'error'
          })
          return
        }

        this.loadGetVipCodeing = true
        getVipCodeManual(this.getCodeData)
          .then(res => {
            if (res.success) {
              if (res.failedCount == 0) {
                this.$message({
                  message: '成功领券' + res.successCount + '张',
                  type: 'success'
                })
                this.getCodeDialogVisible = false
                this.init()
              } else {
                this.$message({
                  message: '成功领券' + res.successCount + '张, 失败' + res.failedCount + '张',
                  type: 'warning'
                })
                this.getCodeDialogVisible = false
                this.init()
              }
            } else {
              this.loadGetVipCodeing = false
            }
          })
      },
      handleRefresh() {
        getRefreshList([4])
          .then(res => {
            if (res.success) {
              this.refreshItems = res.items

              if (this.refreshItems.length > 0) {
                this.refreshIndex = 0
                this.dialogVisible = true
                this.beginRefresh()
              }
            }
          })
      },
      beginRefresh() {
        if (this.refreshIndex == this.refreshItems.length) {
          this.dialogVisible = false
          this.init()
          return
        }

        this.progressText = '正在刷新 ' + (this.refreshIndex + 1) + ' / ' + this.refreshItems.length
        this.refreshPercent = this.refreshIndex / this.refreshItems.length * 100

        refreshVipCode(this.refreshItems[this.refreshIndex].code)
          .then(() => {
            if (this.dialogVisible) {
              this.refreshIndex++
              this.beginRefresh()
            }
          })
      },
      handleExport() {
        getVipCodeListManual(this.params.KeyWords, 0, 0)
          .then(res => {
            if (res.success) {
              let exportData = res.items.map(d => {
                return {
                  '券码': d.code,
                  '券类型': d.kind,
                  '有效期开始时间': this.dateFormat(d.startTime),
                  '有效期结束时间': this.dateFormat(d.endTime),
                  '手机号': d.phone,
                  '会员码': d.membercode,
                  '状态': this.statusFormat(d.status)
                }
              })

              let columnWidths = [{
                wpx: 180
              }, {
                wpx: 130
              }, {
                wpx: 130
              }, {
                wpx: 130
              }, {
                wpx: 90
              }, {
                wpx: 180
              }, {
                wpx: 60
              }]

              var file = createFile(exportData, columnWidths)
              download(file, '后台领券', document)
            }
          })
      },
      handlePageChange(page) {
        this.params.Skip = (page - 1) * this.params.Take
        this.init()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .filter-box {
      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .left-box {
        float: left;

        .item {
          float: left;
          margin-right: 10px;
        }
      }

      .right-box {
        float: right;
      }
    }

    .table-box {
      margin-top: 20px;
    }
  }

  .form-line {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .label {
      width: 130px;
      line-height: 40px;
    }

  }
</style>
